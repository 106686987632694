import { AnalyticsEvent } from 'packages/react-analytics';
import {
  installAdobeLaunch,
  trackAdobeEvent,
  AdobeEvent
} from 'packages/adobe-launch';
import { AcpConfig } from 'apps/acp/packages/acp-config';

export interface AdobeLaunchConfig {
  adobeLaunchScriptUrl: string;
  mode: AcpConfig['mode'];
  variant: AcpConfig['variant'];
}

export const createAdobeLaunchSendAnalytics = ({
  adobeLaunchScriptUrl,
  mode,
  variant
}: AdobeLaunchConfig) => {
  installAdobeLaunch(adobeLaunchScriptUrl);
  trackApplicationStartup({ mode, variant });
  const sendAnalytics = ({ event, payload }: AnalyticsEvent) => {
    if (event === 'nsPageView') {
      trackPageView(
        window.location,
        payload.nsPageView_mfe,
        payload.nsPageView_title,
        payload.nsPageView_accountId
      );
    } else if (['activation', 'registration'].includes(event)) {
      const { eventInfo } = payload as AdobeEvent;
      trackAdobeEvent({ event, eventInfo });
    }
  };
  return sendAnalytics;
};

/**
 * Tracks an ACP page view in AdobeLaunch
 */
export const trackPageView = (
  location: Location,
  mfe: string,
  page: string,
  accountID?: string
) => {
  const { hostname, href } = location;
  trackAdobeEvent({
    event: 'aaPageView',
    pageData: {
      event: 'pageView',
      pageName: `${hostname}|${mfe}|${page}`,
      siteSection: `${hostname}|${mfe}`,
      url: href
    },
    userData: {
      accountID: accountID || 'anonymous'
    },
    siteData: {
      hostName: hostname
    }
  });
};

const trackApplicationStartup = ({
  mode,
  variant
}: Omit<AdobeLaunchConfig, 'adobeLaunchScriptUrl'>) => {
  trackAdobeEvent({
    event: 'Application Startup',
    siteData: {
      nsApp: 'Account Center',
      nsMode: mode.toLowerCase(),
      nsVariant: variant.toLowerCase()
    }
  });
};
